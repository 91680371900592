<template>
    <div class="signUp_container">
        <div class="signUp_banner_image_block">
            <img class="signUp_banner_img" :src="require('@/assets/images/signUp/banner1.png')"/>
            <img class="signUp_welcome_signup_img" :src="require('@/assets/images/signUp/welcome_signup.png')"/>
        </div>
        <div class="signUp_content_container">
            <p class="content_p">《肺癌免疫治疗文献精读全国挑战赛》结合肺癌免疫治疗最新文献精读，真实世界临床实践分享，为医学工作者提供学术风采展示的平台，促进肺癌免疫治疗和临床规范化诊疗观念的提升。</p>
            <p class="content_p">点击下方按钮报名，即可有机会参与到活动中，期待您的学术风采!</p>
            <div class="sign_up_button" @click="linkTo">
                我要报名
            </div>
            <img class="signup_guide_img" @click="showSignUpGuide = true" :src="require('@/assets/images/signUp/signup_guide_btn.png')"/>
        </div>
        <div class="signUp_floor_container">
            <div class="signUp_banner_image_block">
                <img class="signUp_banner_img" :src="require('@/assets/images/signUp/banner2.png')"/>
                <p class="bottom_p">
                    本项目中会传递医药前沿信息、研究进展何科学信息交流，仅供医疗卫生专业人士访问、参考及学习，请确保您的身份是医疗卫生专业人士，否则请退出。
                </p>
            </div>
        </div>
        <div class="sign_up_guide_container" v-show="showSignUpGuide">
            <img :src="require('@/assets/images/signUp/sign_up_guide.jpg')"/>
            <div class="back_button_block">
                <div class="back_button" @click="showSignUpGuide = false">
                    返回
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import weixin from "@/common/weixin";
export default {
    data() {
        return{
            showSignUpGuide:false,
        }
    },
    created() {
        document.getElementsByTagName("title")[0].innerHTML = "肺癌全国挑战赛专区";
    },
    mounted() {
        weixin.wx.ready(() => {
            weixin.shareMessage({
                title: "欢迎报名：肺癌免疫治疗文献精读全国挑战赛",
                url: this.getShareUrl(),
                description: "文献精读结合病例分享，展示您的学术风采",
                logo: "https://io.kclass.imedunion.com/LCC/files/cover/feiai.png",
            });
        });
    },
    methods:{
        getShareUrl() {
            // 正式服记得改
            let url = this.$urls.user.signUp;
            console.log(url)
            return url;
        },
        linkTo(){
            window.location.href = "https://jinshuju.net/f/NVJfQI"
        }
    }
}
</script>
<style>
    .signUp_container{
        width: 100%;
        height: 100%;
        padding: 3vw 4vw 0 4vw;
        overflow: scroll;
        background-image: url("../../../assets/images/signUp/bg.png");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }
    .signUp_banner_image_block{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .signUp_banner_img{
        width: 100%;
        height: auto;
    }
    .signUp_welcome_signup_img{
        margin-top: 3vw;
        height: 7vw;
        width: auto;
    }
    .signup_guide_img{
        margin-top: 6vw;
        height: 5.5vw;
        width: auto;
    }
    .signUp_content_container{
        width: 100%;
        flex: 1 0 0;
        border-radius: 2vw;
        margin-top: 5vw;
        padding: 5vw 4vw;
        /* box-shadow: 0vw -2vw 0vw #ffffffa8; */
        background-image: linear-gradient(#ffffff66, #d8a4b200);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .signUp_content_container P:first-child{
        padding-bottom: 4vw;
    }
    .content_p{
        color: #fff;
        word-break: break-all;
        font-size: 4.4vw;
        line-height: 6vw;
        text-indent: 2em;
    }
    .bottom_p{
        color: #fff;
        font-size: 2vw;
        position: relative;
        bottom: 5vw;
    }
    .sign_up_button{
        margin-top: 6vw;
        color: #fff;
        padding: 2vw 8vw;
        font-weight: 600;
        font-size: 7vw;
        background-image: url("../../../assets/images/signUp/button_gb.png");
        background-size: 100% 100%;
    }
    .signUp_floor_container{
        width: 100%;
        padding: 0 4vw;
    }
    .sign_up_guide_container{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #e5c4cb;
        overflow: scroll;
    }
    .sign_up_guide_img_block{
        width: 100%;
        height: 100%;
        overflow: scroll;
        position: relative;
        top: 0;
        left: 0;
    }
    .sign_up_guide_container img{
        width: 100%;
        height: auto;
    }
    .back_button_block{
        width: 100%;
        margin: 6vw 0;
        display: flex;
        justify-content: center;
    }
    .back_button{
        /* width: 30vw; */
        color: #fff;
        padding: 2vw 15vw;
        font-weight: 600;
        font-size: 7vw;
        background-image: url("../../../assets/images/signUp/button_gb.png");
        background-size: 100% 100%;
    }
</style>